<template>
  <div class="checkout--card">
    <div class="title--text">{{ $t('booking.guide.payment') + paymentMethodName }}</div>
    <div class="text-center">
      <div class="pb3">
        <p>{{ $t('booking.paymentValue') }}</p>
        <h3 class="bold mt1" id="finalPrice">
          {{ $n(price, 'currency', 'id-ID') }}
        </h3>
        <a
          class="text-helper"
          style="position: relative"
          id="copyToClipboard"
          @click="copyToClipboard(price)"
          href="javascript:"
        >
          {{ $t('booking.copyValue') }}
          <popover v-show="copySucceeded"></popover>
        </a>
      </div>

      <div class="pt3 pb-3">
        <p>{{ $t('booking.no') }} {{ paymentMethodName }}</p>
        <h3 class="bold mt1">{{ virtualAccountNumber }}</h3>
        <a
          class="text-helper"
          style="position: relative"
          id="copyToClipboardVa"
          @click="copyToClipboardVa(virtualAccountNumber)"
          href="javascript:"
        >
          {{ $t('booking.copyBank') }}
          <popover v-show="copyVaSucceeded"></popover>
        </a>
      </div>
      <div class="pt3">
        <p>{{ $t('booking.orderNo') }}</p>
        <h3 class="bold primary mt1">{{ orderNumber }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
const Popover = defineAsyncComponent(() => import('@/components/utils/popover.vue'));
export default {
  name: 'panelDescription',
  // props: ['bookingOrder'],
  props: ['paymentMethodName', 'price', 'virtualAccountNumber', 'viewMybookingUrl', 'orderNumber'],
  components: {
    Popover,
  },
  data: () => ({
    copySucceeded: false,
    copyVaSucceeded: false,
  }),
  methods: {
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      this.handleCopy(true);
    },
    async handleCopy(status) {
      this.copySucceeded = status;
      let self = this;
      setTimeout(() => {
        self.copySucceeded = false;
      }, 2000);
    },
    copyToClipboardVa(value) {
      navigator.clipboard.writeText(value);
      this.handleCopyVa(true);
    },
    async handleCopyVa(status) {
      this.copyVaSucceeded = status;
      let self = this;
      setTimeout(() => {
        self.copyVaSucceeded = false;
      }, 2000);
    },
  },
};
</script>

<style scoped></style>
